import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Ian = () => {
	const { ian: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Clinical Prof. Ian Yusoff`]}
				description="About Clinical Prof. Ian Yusoff"
			/>
			<ProfilePage
				title="Ian Yusoff - Interventional Endoscopist & Gastroenterologist"
				name="Clinical Prof. Ian Yusoff"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="Endoscopic Ultrasound and Pancreaticobiliary Endoscopy"
				education={[
					"Undergraduate: University of Western Australia",
					"Postgraduate GI Training: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Royal Perth Hospital, Perth, Western Australia",
					"Fellowship: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Fellowship: McGill University, Montreal, Canada",
				]}
				description={[
					"Clinical. Prof. Ian Yusoff is a gastroenterologist with subspecialty interests in pancreaticobiliary diseases. Procedurally, his main interests are in endoscopic ultrasound and pancreaticobiliary endoscopy. He is an integral part of the new endoscopic ultrasound service established at Hollywood in 2019.",
					"His specific interests are patients with pancreatitis, pancreatic cystic disease and pancreaticobiliary malignancy. Given the complex nature of these conditions, all patients are managed in a multidisciplinary context to ensure adherence to best practice evidence-based principles.",
					"He is actively involved in clinical research with specific interests in outcomes of endoscopy and technology assessment.",
					"Clinical Prof. Yusoff also has a public appointment at Sir Charles Gairdner Hospital and an adjunct appointment at The University of Western Australia as Clinical Associate Professor of Medicine.",
					"He is currently on a number of national advisory committees including the Cancer Council of Australia and the Royal Australasian College of Pathologists.",
				]}
				honours={[
					"",
				]}
				background="Clinical Prof. Ian Yusoff completed his gastroenterology training in Perth. He subsequently undertook fellowship training at McGill University in interventional endoscopy and at the University of Montreal in endoscopic ultrasound. He also completed further studies in epidemiology while at McGill University."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Public appointment - Osborne Park Hospital (ASI Open Access Endoscopy)",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
					"Clinical Associate Professor of medicine - The University of Western Australia",
				]}
				memberships={[
					"Cancer Council of Australia",
					"Gastroenterological Society of Australia (GESA)",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"The Royal Australasian College of Physicians (RACP)",
				]}
				personal={[
					"",
				]}
				publications={[
					{
						publicationTitle:
							"The role of cytology",
						authors: "1.	Kumarasinge P and Yusoff I",
					},
					{
						publicationTitle: "Definitive therapy of colonic angioectasia by submucosal coagulation",
						authors: "Sriram N, Bar-Yishay I, Kumarasinghe P, Yusoff I, Segarajasingam D, Bourke MJ, Raftopoulos S",
					},
					{
						publicationTitle: "Australian clinical practice guidelines for the diagnosis and management of Barrett's esophagus and early esophageal adenocarcinoma",
						authors: "Whiteman DC, Appleyard M, Bahin FF, Bobryshev YV, Bourke MJ, Brown I, Chung A, Clouston A, Dickins E, Emery J, Eslick GD, Gordon LG, Grimpen F, Hebbard G, Holliday L, Hourigan LF, Kendall BJ, Lee EY, Levert-Mignon A, Lord RV, Lord SJ, Maule D, Moss A, Norton I, Olver I, Pavey D, Raftopoulos S, Rajendra S, Schoeman M, Singh R, Sitas F, Smithers BM, Taylor AC, Thomas ML, Thomson I, To H, von Dincklage J, Vuletich C, Watson DI, Yusoff IF",
					},
					{
						publicationTitle: "Hot avulsion: a modification of an existing technique for management of nonlifting areas of a polyp (with video)",
						authors: "Veerappan SG, Ormonde D, Yusoff IF, Raftopoulos SC",
					},
					{
						publicationTitle: "Intramural gastric abscess from dropped gallstones presenting as a subepithelial lesion (with video)",
						authors: "Chen HC, Tee CT, Segarajasingam DS, Raftopoulos SC, Yusoff IF",
					},
					{
						publicationTitle: "Wire assisted transpancreatic septotomy, needle knife precut or both for difficult biliary access",
						authors: "Chan CH, Brennan FN, Zimmerman MJ, Ormonde DG, Raftopoulos SC, Yusoff IF",
					},
					{
						publicationTitle: "Loop tip wire guided cannulation is a safe and effective technique even when performed by trainee endoscopists. (abstract)",
						authors: "Chan CH, Brennan FC, Raftopoulos SC, Yusoff F",
					},
					{
						publicationTitle: "Outcomes of endoscopic mucosal resection of Barrett’s mucosa with high grade dysplasia and intramucosal oesophageal cancer. (abstract)",
						authors: "Raftopoulos SC, Ormonde D, Brennan FN, Yusoff IF",
					},
					{
						publicationTitle: "Gastric intraepithelial neoplasia in a Western population",
						authors: "Raftopoulos SC, Kumarasinghe P, de Boer B, Iacobelli J, Kontorinis N, Fermoyle S, Olynyk J, Forrest C, Ee HC, Yusoff IF",
					},
					{
						publicationTitle: "A cohort study of missed and new cancers after esophagogastroduodenoscopy",
						authors: "Raftopoulos SC, Segarajasingam DS, Burke V, Ee HC, Yusoff IF",
					},
					{
						publicationTitle: "Epstein-Barr virus-associated cholangiopathy: a new disease entity?",
						authors: "Raftopoulos SC, Garas G, Price R, de Boer WB, Jeffrey GP, Yusoff IF",
					},
					{
						publicationTitle: "Gastroenterology Emergencies in the Coronary Care Unit",
						authors: "Ee H, Yusoff I",
					},
					{
						publicationTitle: "Radiotherapy in Locally Advanced Pancreas Cancer",
						authors: "McGrath A, Yusoff I et al",
					},
					{
						publicationTitle: "Exercise as medicine in the management of pancreatic cancer: a case study",
						authors: "Cormie P, Spry N, Jasas K, Johansson M, Yusoff IF, Newton RU, Galvão DA",
					},
					{
						publicationTitle: "Detection of Hypoxia with F-18 fluoromisonidazole (F-18 FMISO) Positron Emission Tomography/Computed Tomography (PET/CT) in Suspected or Proven Pancreatic Cancer",
						authors: "Segard T, Robins PD, Yusoff IF, Ee H, Morandeau L, Campbell EM, Francis RJ",
					},
					{
						publicationTitle: "Optimizing the multimodal approach to pancreatic cyst fluid diagnosis : Developing a volume-based triage protocol",
						authors: "Chai SM, Herba K, Kumarasinghe MP, de Boer WB, Amanuel B, Grieu-Iacopetta F, Lim EM, Segarajasingam D, Yusoff I, Choo C, Frost F",
					},
					{
						publicationTitle: "Diagnosis and management of cholecystitis and cholangitis",
						authors: "Yusoff IF, Barkun JS, Barkun AN",
					},
					{
						publicationTitle: "Staging esophageal adenocarcinoma",
						authors: "Yusoff IF, Sahai AV",
					},
				]}
			/>
		</>
	);
};

export default Ian;
